import React, {Component} from 'react';

export class EmailForm extends Component {
  constructor() {
    super();
    this.state = {message: ''};
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
	  //e.preventDefault();
	  //e.stopPropagation();
    this.setState({message: 'Thank you!'});
    setTimeout(() => {
      this.setState({message: ''});
    }, 3000);
  }

  render() {
    const {message} = this.state;
    return (
      <form
        id="signup-form"
        onSubmit={this.onSubmit}
        method="post"
        action="https://www.getdrip.com/forms/XXX/submissions"
        data-drip-embedded-form="XXX">
        <input
          type="email"
          name="fields[email]"
          id="drip-email"
          placeholder="Enter your email..."
	  required
        />
        <input
          type="submit"
          value="Subscribe"
          data-drip-attribute="sign-up-button"
        />
        <span className={`${message ? 'visible success' : ''} message`}>
          {message}
        </span>
      </form>
    );
  }
}

export default EmailForm;
